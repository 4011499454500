<template>
    <div class="info-panel">
        <div class="info-panel-title">
            <span class="color-line" :style="`background: ${lineColor}`"></span>
            <span>{{infoTitle}}</span>
        </div>
        <div class="info-panel-body">
            <slot></slot>
        </div>
    </div>
</template>

<script>

export default {
    components: {},
    props: {
        infoTitle: {
            type: String,
            default: '面板标题'
        },
        lineColor: {
            type: String,
            default: '#EC5D6E'
        },
    },
    data() {
        return {
        }
    },
}
</script>
<style lang="scss" scoped>
.info-panel {
    // width: 100%;
    margin-bottom: 14px;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #DCE2E9;
    .info-panel-title {
        @include flex(row,flex-start,center);
        height: 60px;
        padding: 0 30px;
        color: #363D42;
        font-size: 16px;
        line-height: 22px;
        border-bottom: 1px solid #DCE2E9;
        .color-line {
            width:4px;
            height:20px;
            border-radius:2px;
            margin-right: 14px;
        }
    }
}
</style>